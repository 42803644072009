import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comming-soon-message',
  templateUrl: './comming-soon-message.component.html',
  styleUrls: ['./comming-soon-message.component.scss']
})
export class CommingSoonMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
