<app-toolbar></app-toolbar>

<div class="content">
    <div class="content-2">
        <div class="content-card">
            <div class="card">
                <span class="title">
                    Panel de Analíticas
                </span>
                <button (click)="openComingSoon()">Ver</button>
            </div>
            <div class="card">
                <span class="title">
                    Directorio
                </span>
                <button (click)="openComingSoon()">Ver</button>
            </div>
            <div class="card">
                <span class="title">
                    Membresía
                </span>
                <button routerLink="/membresia">Ver</button>
            </div>
            <div class="card">
                <span class="title">
                    Documentos
                </span>
                <button routerLink="/documentos">Ver</button>
            </div>
        </div>
    </div>

</div>