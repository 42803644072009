import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Emprendamos';

  public displayLayout: boolean = false;
  private subs = new SubSink();

  constructor(
    private router: Router,
    private location: Location,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ){}

}
